import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';

@Component({
  // standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{
  userDetails:any;
  constructor(private auth : AuthService){
    const getUserDetails:any = this.auth.getUserDetails() ? this.auth.getUserDetails() : localStorage.getItem('user_details');
    this.userDetails = JSON.parse(getUserDetails)
    // console.log("=====xuserDetails+++++++++++",this.userDetails)
  }

  ngOnInit(): void {
    
  }
  toggleNav(){
    const toggleBtn:any = document.getElementById('toggle_btn');

    const body = document.body;
    if (body.classList.contains('mini-sidebar')) {
      body.classList.remove('mini-sidebar');
      // toggleBtn.classList.add('active');
      // localStorage.setItem(screenModeNightTokenState, 'night');
      setTimeout(() => {
        body.classList.remove('mini-sidebar');
        document.querySelector(".header-left")?.classList.add("active");
      }, 100);
    }else {
      body.classList.add('mini-sidebar');
      toggleBtn.classList.remove('active');
      // localStorage.removeItem(screenModeNightTokenState);
      setTimeout(() => {
        body.classList.add('mini-sidebar');
        document.querySelector(".header-left")?.classList.remove("active");
      }, 100);
  } 
} 

toggleNavMobile(){
  const toggleBtn:any = document.getElementById('mobile_btn');

  const body = document.body;
  // const wrapper = document.querySelector(".main-wrapper");
  
  if (document.querySelector(".slide-nav")) {
    document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
    
    body.classList.remove('menu-opened');
    // toggleBtn.classList.add('active');
    // localStorage.setItem(screenModeNightTokenState, 'night');
    setTimeout(() => {
      body.classList.remove('mini-sidebar');
      document.querySelector(".header-left")?.classList.add("active");
    }, 100);
  }else {
    document.querySelector(".main-wrapper")?.classList.add("slide-nav");

    body.classList.add('menu-opened');
    toggleBtn.classList.remove('active');
    // localStorage.removeItem(screenModeNightTokenState);
    setTimeout(() => {
      body.classList.add('mini-sidebar');
      document.querySelector(".header-left")?.classList.remove("active");
    }, 100);
} 
} 

ngAfterViewInit() {
  feather.replace();
}

logOut(){
  this.auth.logOut()
}
onClickedOutside(e: Event) {
  const toggleBtn:any = document.getElementById('mobile_btn');
  const body = document.body;
  if (document.querySelector(".slide-nav")) {
    document.querySelector(".main-wrapper")?.classList.remove("slide-nav");
    
    body.classList.remove('menu-opened');
    // toggleBtn.classList.add('active');
    // localStorage.setItem(screenModeNightTokenState, 'night');
    setTimeout(() => {
      body.classList.remove('mini-sidebar');
      document.querySelector(".header-left")?.classList.add("active");
    }, 100);
  }
}
}
