import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HeaderComponent } from './component/header/header.component';
import { CustomDatePipe } from '../_pipe/datepipe.pipe';
import { AppMaterialModule } from '../_material/material/material.module';
import { CustomDateRangeComponent } from './component/custom-date-range/custom-date-range.component';
import { dateRangeComponent } from './component/date-range/date-range.component';
import { searchDropDownFilter } from '../_pipe/search-filter.pipe';

@NgModule({
  declarations: [CustomDatePipe,CustomDateRangeComponent,dateRangeComponent,searchDropDownFilter],
  exports: [CustomDatePipe,CustomDateRangeComponent,dateRangeComponent,searchDropDownFilter], 
  imports: [CommonModule,AppMaterialModule],
})

// @NgModule({
//   declarations: [
//     HeaderComponent,
//     SidebarComponent
//   ],
//   imports: [
//     CommonModule
//   ],
//   exports:[
//     HeaderComponent,
//     SidebarComponent
//   ]
// })
export class SharedModule { }
