import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { ImportOrderComponent } from './orders/container/import-order/import-order.component';
import { auth2Guard } from './_guard/auth2.guard';
import { authGuard } from './_guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: '',
    // canLoad: [auth2Guard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [authGuard],
    children: [
      // {
      //   path: 'import-order',
      //   component: ImportOrderComponent,
      //   // canActivate: [AuthGuard],
      //   data: { title: 'Opollo WMS' }
      // },
      {
        path: 'order',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'dashboard',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'setting',
        // canActivate: [auth2Guard],
        loadChildren: () => import('./setting/setting.module').then(m => m.SettingModule)
      },

    ]
  },
  { path: '**', redirectTo: '' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
