<div class="sidebar" id="sidebar ">
    <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu">
            <!-- <mat-accordion class="app-nav-accordion">
                <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                        <mat-panel-title><a href="javascript:void(0);">Shipments<span
                                   ></span></a></mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-nav-list>
                        <ul>
                            <li><a>Inbound</a></li>
                            <li><a>Outbound</a></li>
                            <li><a>Last Mile</a></li>
                        </ul>
                    </mat-nav-list>
                </mat-expansion-panel>
            </mat-accordion> -->
            <ul>
                <!-- <li class="submenu-open">
                    <h6 class="submenu-hdr">Insights</h6>
                    <ul>
                        <li>
                            <a routerLink="order/create-order"><i data-feather="package"></i><span>Orders</span></a>
                        </li>
                        <li class="submenu">
                            <a href="javascript:void(0);"><i data-feather="truck"></i><span>Shipments</span><span
                                    class="menu-arrow"></span></a>
                            <ul>
                                <li><a>Inbound</a></li>
                                <li><a>Outbound</a></li>
                                <li><a>Last Mile</a></li>
                            </ul>
                        </li>
                        <li>
                            <a><i data-feather="check-circle"></i><span>SLA's</span></a>
                        </li>
                        <li>
                            <a><i data-feather="alert-circle"></i><span>NDR Summary</span></a>
                        </li>
                        <li>
                            <a><i data-feather="alert-triangle"></i><span>RTO's</span></a>
                        </li>

                    </ul>
                </li> -->

                <li class="submenu-open" *ngIf="roleService.isActiveModule('Insights')"> 
                    <h6 class="submenu-hdr" *ngIf="roleService.isActiveModule('Insights')">Insights</h6>
                    <ul>
                        <li *ngIf="roleService.isActiveSubmodule('Orders Report')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/dashboard/order-report" [queryParams]="{active: true}" title="Active" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                            <span>Orders Report</span></a></li>
                    </ul>
                </li>
                <li class="submenu-open"  *ngIf="roleService.isActiveModule('Orders')">
                    <h6 class="submenu-hdr">Orders</h6>
                    <ul>
                        <li *ngIf="roleService.isActiveSubmodule('Active')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/order" [queryParams]="{active: true}" title="Active" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                            <span>Active </span></a></li>
                        <li *ngIf="roleService.isActiveSubmodule('Pending')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/order" [queryParams]="{pending: true}" title="Pending" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                                <span>Pending </span></a></li>
                        <li *ngIf="roleService.isActiveSubmodule('Exception')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="/order" [queryParams]="{exception: true}" title="Exception" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                            <span>Exception </span></a></li>
                        <!-- <li><a><i data-feather="refresh-cw"></i><span>Pending</span></a></li> -->
                    </ul>
                </li>
                <li class="submenu-open"  *ngIf="roleService.isActiveModule('Utilities')">
                    <h6 class="submenu-hdr">Utilities</h6>
                    <ul>
                        <li *ngIf="roleService.isActiveSubmodule('Upload Orders')"routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="order/import-order" title="Upload Orders" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                            <span>Upload Orders</span></a>
                        </li>
                        <li *ngIf="roleService.isActiveSubmodule('Upload Pincodes')" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a title="Upload Pincodes" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                            <span>Upload Pincodes</span></a></li>
                        <li *ngIf="roleService.isActiveSubmodule('Configure Carriers')"><a  title="Configure Carriers" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                            <span>Configure Carriers</span></a></li>
                    </ul>
                </li>
                <li class="submenu-open" >
                    <h6 class="submenu-hdr">Settings</h6>
                    <ul>
                        <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"><a routerLink="setting/users" title="Upload Orders" id="mobile_btn" (click)="toggleNavMobile()">
                            <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                            <!-- <svg viewBox="0 0 24 24" width="18" height="18" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg> -->
                            <span>Users</span></a>
                        </li>
                    </ul>
                </li>
                <li class="submenu-open">
                    <!-- <h6 class="submenu-hdr">Settings</h6> -->
                    <ul>
                        <!-- <li class="submenu">
                            <a href="javascript:void(0);"><i data-feather="settings"></i><span>Settings</span><span
                                    class="menu-arrow"></span></a>
                            <ul>
                                <li><a>General Settings</a></li>
                                <li><a>Email Settings</a></li>
                                <li><a>Payment Settings</a></li>
                                <li><a>Currency Settings</a></li>
                                <li><a>Group Permissions</a></li>
                                <li><a>Tax Rates</a></li>
                            </ul>
                        </li> -->
                        <li>
                            <a (click)="logOut()" title="Logout"><i data-feather="log-out"></i><span>Logout</span> </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>