import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as feather from 'feather-icons';
import { roleService } from '../../services/rolebase-services';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit{
  isMobile: boolean = false;
  isModuleActive:boolean=false;
  isSubModuleActive:boolean= false;

  
  constructor(private auth: AuthService, public roleService: roleService) {
    this.checkIfMobile();
  }
  
  ngAfterViewInit() {
    feather.replace();
  }

  ngOnInit() {
    this.roleService.fetchRoleList();
      
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile(); // Check whenever the window is resized
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth < 769; 
  }

  toggleNavMobile() {
    if (this.isMobile) {
      const toggleBtn: any = document.getElementById('mobile_btn');

      const body = document.body;
      // const wrapper = document.querySelector(".main-wrapper");

      if (document.querySelector(".slide-nav")) {
        document.querySelector(".main-wrapper")?.classList.remove("slide-nav");

        body.classList.remove('menu-opened');
        // toggleBtn.classList.add('active');
        // localStorage.setItem(screenModeNightTokenState, 'night');
        setTimeout(() => {
          body.classList.remove('mini-sidebar');
          document.querySelector(".header-left")?.classList.add("active");
        }, 100);
      } else {
        document.querySelector(".main-wrapper")?.classList.add("slide-nav");

        body.classList.add('menu-opened');
        toggleBtn.classList.remove('active');
        // localStorage.removeItem(screenModeNightTokenState);
        setTimeout(() => {
          body.classList.add('mini-sidebar');
          document.querySelector(".header-left")?.classList.remove("active");
        }, 100);
      }
    }
  }


  logOut() {
    this.auth.logOut()
  }
}
