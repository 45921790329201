// token.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authRequest:any;
  constructor(private auth : AuthService,private ErrorService: ErrorHandlingService){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the authentication token from wherever you store it (e.g., localStorage, a service, etc.)
    // console.log("localStorage authToken",localStorage.getItem('access_token'))
    const authToken = this.auth.getAuthToken() ? this.auth.getAuthToken() : localStorage.getItem('access_token') 
    var apidetector = request.url.split('/')[request.url.split('/').length - 1].split('?')[0];
    // console.log(apidetector)
    const requestToHandle = authToken
            ? request.clone({
                headers: request.headers.set('Authorization', `Bearer ${authToken}`),
                setHeaders: { 'DEVICE-TYPE': 'Web',VER:'1' },
            })
            : request;
            return next.handle(requestToHandle).pipe(tap((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                  if (event.status && (event.status === 200 || 201)) {
                      
                  }
              }
          }, (error:any) => {
              if (error.status === 401) {
                this.auth.logOut()
              } else if(apidetector == 'checkUserName'){
                // console.log(apidetector)
                // return error;
              }else{
                this.ErrorService.errormsg(error ? error.error.responseMessage : 'Something went wrong');

              }
          }));
  }
}

export let tokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor, 
  multi: true,
};