import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { Configuration } from './constants/constants/config';
import { SharedModule } from './shared/shared.module';
import { ResponseInterceptor, responseProvider } from './_interceptor/response.interceptor';
import { TokenInterceptor, tokenProvider } from './_interceptor/token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AppMaterialModule } from './_material/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { SidebarComponent } from './shared/component/sidebar/sidebar.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { OrderReportComponent } from './dashboard/component/order-report/order-report.component';
import { ChartModule } from 'primeng/chart';
import { ClickOutsideModule } from 'ng-click-outside';

// import { DatepipePipe } from './_pipe/datepipe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    // DatepipePipe,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    AppMaterialModule,BrowserAnimationsModule,ChartModule,
    ClickOutsideModule
  ],
  exports:[ 
    HeaderComponent,
    SidebarComponent
  ],
  providers: [AuthService,Configuration,DatePipe,
    tokenProvider,responseProvider,CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
